import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Container } from './Container'

import { PracticeArea } from '../interfaces'

function Footer() {
  const data = useStaticQuery(graphql`
    {
      practiceAreas: allDatoCmsPracticeArea {
        nodes {
          title
          slug
        }
      }
    }
  `)

  const currentYear = new Date().getFullYear()

  return (
    <footer>
      <div className="bg-gray-800 py-10 md:py-16 mt-12 md:mt-16 lg:mt-20">
        <Container>
          <div className="md:flex justify-between">
            <div className="text-white w-full">
              <h3 className="border-b-2 border-gray-400 pb-1 mb-3 inline-block w-64">Verksamhetsområden</h3>
              <ul>
                {data.practiceAreas.nodes.map((item: PracticeArea, idx: number) => (
                  <li key={idx}>
                    <Link to={`/verksamhetsomraden/${item.slug}/`} className="text-sm hover:underline">
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="text-white w-full mt-8 md:mt-0">
              <h3 className="border-b-2 border-gray-400 pb-1 mb-3 inline-block w-64">Övrigt</h3>
              <ul>
                <li>
                  <Link to="/information/" className="text-sm hover:underline">
                    Länkar
                  </Link>
                </li>
                <li>
                  <Link to="/personuppgiftspolicy/" className="text-sm hover:underline">
                    Personuppgiftspolicy
                  </Link>
                </li>
              </ul>
            </div>

            <div className="text-white w-full mt-8 md:mt-0">
              <h3 className="border-b-2 border-gray-400 pb-1 mb-3 inline-block w-64">Adress</h3>
              <ul className="text-sm">
                <li>Advokatfirman Sievo AB</li>
                <li>Stockholmsvägen 18</li>
                <li>181 50 Lidingö</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>

      <div className="bg-gray-900">
        <Container>
          <p className="py-2 text-gray-400 text-xs text-center">Copyright © {currentYear}</p>
        </Container>
      </div>
    </footer>
  )
}

export { Footer }
