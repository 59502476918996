import { useEffect, RefObject } from 'react'

const useOutsideClick = (ref: RefObject<HTMLDivElement>, callback: Function) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export { useOutsideClick }
