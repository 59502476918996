import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

function Container(props: Props) {
  return <div className="max-w-6xl mx-auto px-6">{props.children}</div>
}

export { Container }
