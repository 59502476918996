import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { css } from '@emotion/core'

import { Header } from './Header'
import { Footer } from './Footer'

const site = css`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const siteContent = css`
  flex-grow: 1;
`

interface Props {
  children: ReactNode
  spaceTop?: boolean
}

function Layout(props: Props) {
  return (
    <div css={site}>
      <Header />

      <main
        css={siteContent}
        className={classNames({
          'pt-12': props.spaceTop,
        })}
      >
        {props.children}
      </main>

      <Footer />
    </div>
  )
}

export { Layout }
