import React, { useState, useRef, RefObject } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import { useMedia } from 'use-media'
import { FaChevronDown, FaChevronUp, FaBars } from 'react-icons/fa'

import { Container } from '../Container'

import { PracticeArea } from '../../interfaces'

import tailwind from '../../../tailwind.config.js'
import { useOutsideClick } from '../../hooks/useOutsideClick'

function Header() {
  const [areasMenuOpen, setAreasMenuOpen] = useState<boolean>(false)
  const areasMenuClicked = () => setAreasMenuOpen(prev => !prev)

  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const menuClicked = () => setMenuOpen(prev => !prev)

  const screenIsWide = useMedia({ minWidth: tailwind.theme.screens.md })

  const { practiceAreas } = useStaticQuery(graphql`
    {
      practiceAreas: allDatoCmsPracticeArea {
        nodes {
          slug
          title
        }
      }
    }
  `)

  const areasMenuRef = useRef() as RefObject<HTMLDivElement>
  useOutsideClick(areasMenuRef, () => {
    setAreasMenuOpen(false)
  })

  return (
    <header className="border-b-2">
      <div className="bg-gray-100 text-center md:text-left py-6">
        <Container>
          <Link to="/" className="py-3">
            <h2 className="font-bold font-serif uppercase text-xl md:text-3xl text-gray-800 border-b-2 border-gray-700 inline-block">
              Advokatfirman Sievo AB
            </h2>
          </Link>
        </Container>
      </div>

      <div className="bg-gray-200">
        <Container>
          <div className="md:hidden text-center">
            <button
              onClick={menuClicked}
              className="uppercase font-bold text-lg text-gray-800 bg-gray-300 py-2 px-4 focus:outline-none"
            >
              <FaBars />
            </button>
          </div>

          <nav
            className={classNames({
              hidden: !menuOpen && !screenIsWide,
              'absolute w-full max-w-sm bg-gray-400 z-50 left-0 right-0 mx-auto flex flex-col text-center shadow-md': menuOpen,
            })}
          >
            <div
              className={classNames('flex text-lg md:text-base', {
                'flex-col': menuOpen && !screenIsWide,
              })}
            >
              <Link to="/" className="p-4 md:p-3 hover:bg-white" activeClassName="bg-white">
                Hem
              </Link>

              <div className="relative" ref={areasMenuRef}>
                <button
                  className="p-4 md:p-3 flex items-center justify-center w-full focus:outline-none hover:bg-white"
                  onClick={areasMenuClicked}
                >
                  <span>Verksamhetsområden</span>
                  <span className="relative text-gray-600" style={{ left: 5 }}>
                    {areasMenuOpen ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </button>

                <div
                  className={classNames('md:bg-gray-300 md:absolute', {
                    'z-10': areasMenuOpen,
                    'z-10 shadow w-48': areasMenuOpen && screenIsWide,
                  })}
                  style={{
                    top: 50,
                  }}
                >
                  {areasMenuOpen &&
                    practiceAreas.nodes.map((area: PracticeArea, idx: number) => (
                      <Link
                        key={idx}
                        to={`/verksamhetsomraden/${area.slug}`}
                        className="block p-4 md:p-6 hover:bg-white"
                      >
                        {area.title}
                      </Link>
                    ))}
                </div>
              </div>

              <Link to="/medarbetare/" className="p-4 md:p-3 hover:bg-white" activeClassName="bg-white">
                Medarbetare
              </Link>
              <Link to="/kontakt/" className="p-4 md:p-3 hover:bg-white" activeClassName="bg-white">
                Kontakt
              </Link>
            </div>
          </nav>
        </Container>
      </div>
    </header>
  )
}

export { Header }
